html {
  font-size: 10px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}
.App-header__title {
  font-weight: bold;
  padding-left: 10px;
  text-align: left;
}

.App-body .side-nav {
  background: #f8f8f8;
  border: 1px solid #e7e7e7;
  border-top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: fixed;
  top: 50px;
}
.App-body .side-nav__content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.App-body .side-nav__content--header {
  color: #555555;
  font-size: 18px;
  padding: 10px 10px 5px;
}
.App-body .side-nav__content--links {
  color: #428bca;
  font-size: 14px;
  overflow: hidden;
  padding: 5px 10px;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}
.App-body .side-nav__content--active {
  background: #428bca;
  color: rgb(255, 255, 255);
}
a:hover:not(.App-body .side-nav__content--active) {
  background: #eeeeee;
}
.App-body .side-nav__content a:focus {
  background: #428bca;
  color: white;
}
.App-body__content {
  top: 50px;
}

.nav {
  align-items: center;
  background: #f8f8f8;
  border: 1px solid #e7e7e7;
  border-width: 0 0 1px;
  color: #777777;
  display: flex;
  justify-content: space-between;
  left: 0;
  max-height: 50px;
  padding-left: 10px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  z-index: 1030;
  -webkit-transform: translate3d(0, 0, 0);
}
.nav__title {
  align-items: flex-start;
  display: flex;
  width: 100%;
}
.nav__user {
  align-items: center;
  color: #44474d;
  display: flex;
  font-size: 14px;
  justify-content: center;
  width: 15%;
}
.nav__user img {
  border-radius: 50%;
  height: 40px;
  margin-left: 10px;
  width: 40px;
}
.nav__links {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 15%;
}
.nav__links--text-style {
  color: #777777;
  font-size: 14px;
  text-decoration: none;
}

.App-link {
  color: #09d3ac;
}

@media only screen and (max-width: 575px) {
  .App-body .side-nav {
    position: relative;
  }
}
.paginate__container {
  color: #2877e4;
  font-size: 12px;
  font-weight: 500;
  list-style: none;
}
.paginate__list {
  cursor: pointer;
  display: inline-block;
  margin: 5px;
}
.paginate__list--active {
  background: #eceef3;
  border-radius: 5px;
  color: #44474d;
  padding: 5px 12px;
}
.paginate__list--link:focus {
  outline: none;
}
.paginate__previous, .paginate__next {
  cursor: pointer;
  display: inline-block;
  margin: 3px;
}
.paginate__previous--link:focus, .paginate__next--link:focus {
  outline: none;
}

.file-upload__dropzone {
  align-items: center;
  background-color: #f9f9f9;
  border-color: #dddddd;
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  color: #c6c6c6;
  display: flex;
  flex: 1;
  flex-direction: column;
  outline: none;
  padding: 20px;
  transition: border 0.24s ease-in-out;
}
.file-upload__dropzone--active {
  border-color: #2877e4;
}
.file-upload__image-preview {
  height: 180px;
  object-fit: contain;
  object-position: center;
  width: 320px;
}

.list {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  text-align: left;
}
.list__pagination {
  display: flex;
  justify-content: flex-end;
}
.list__header {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 10px;
}
.list__header--no-padding {
  padding: 0;
}
.list__search-post-icon {
  right: 12px;
  top: -13px;
  width: 20px;
}
.list__title {
  color: #333333;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 20px;
  text-align: left;
}
.list__data {
  border-top: 1px solid #e3e3e3;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  min-height: 42px;
  padding-left: 10px;
  padding-right: 10px;
}
.list__data:nth-child(odd) {
  background: #f9f9f9;
}
.list__value {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  max-height: 42px;
  width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.list__value-clickable {
  cursor: pointer;
}
.list__value-clickable-icon {
  font-size: 12px;
  margin-left: 4px;
}
.list__value-clickable-icon:last-of-type {
  margin-left: 0;
}
.list__value-clickable-icon svg {
  fill: #888c9d;
}
.list__value-clickable-icon--active svg {
  fill: #000000;
}
.list__value-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.list__value-title {
  text-transform: capitalize;
}
.list__value--actions {
  justify-content: center;
  width: 230px;
}
.list__value--actions-small {
  width: 150px;
}
.list__table {
  border: 1px solid #e3e3e3;
  border-top: none;
}
.list__tabs .r-tabs__tab-list {
  list-style-position: inside;
  list-style-type: disc;
  padding-inline-start: 0;
}

.header {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
}
.header__title {
  color: #333333;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 20px;
  text-align: left;
}
.header__actions {
  display: inline-flex;
}
.header__children {
  display: flex;
  margin-left: auto;
}

.render-image__preview {
  display: block;
  padding-bottom: 10px;
  width: 480px;
}
.render-image__preview img {
  display: block;
  height: auto;
  max-width: 100%;
}
.render-image__label {
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 5px 10px;
}

.error-component {
  background: rgba(249, 185, 60, 0.6);
  color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border: 1px solid black;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  white-space: normal;
  max-width: 80%;
  box-sizing: border-box;
}

.create-new-hotel__wrapper {
  margin-left: auto;
  display: inline-block;
}
.create-new-hotel-content__attributes {
  margin: 10px;
}
.create-new-hotel-content__attributes-map {
  height: 250px;
  width: 100%;
  margin-bottom: 10px;
}
.create-new-hotel-content__attributes input {
  margin-bottom: 10px;
}
.create-new-hotel-content__attributes textarea {
  margin-bottom: 10px;
  width: 880px;
}
.create-new-hotel-content__submit {
  float: left;
  margin: 0 10px 10px;
  padding-bottom: 10px;
}
.create-new-hotel-content__cancel {
  float: right;
  margin: 0 10px 10px;
  padding-bottom: 10px;
}
.create-new-hotel-content__list {
  margin-top: 20px;
  font-weight: normal;
}

.hotel-combine-editor__wrapper {
  display: inline-block;
  margin-left: auto;
}
.hotel-combine-editor-content__hints {
  color: #44474d;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}
.hotel-combine-editor-content .hotel-attributes {
  margin: 10px;
}
.hotel-combine-editor-content .hotel-attributes-attribute {
  border-bottom: 2px solid #c6c6c6;
  margin: 15px 0 15px 0;
}
.hotel-combine-editor-content .hotel-attributes-attribute__name {
  color: black;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}
.hotel-combine-editor-content .hotel-attributes-attribute__value-selector {
  margin: 5px 0 10px 0;
}
.hotel-combine-editor-content .hotel-attributes-attribute__value-selector .radio {
  display: flex;
  margin-bottom: 8px;
}
.hotel-combine-editor-content .hotel-attributes-attribute__value-selector .radio__label {
  color: black;
}
.hotel-combine-editor-content .hotel-attributes-attribute__value-description {
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #333333;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 5px 10px;
  margin-top: 0;
}
.hotel-combine-editor-content .hotel-attributes-attribute__value-description-json {
  overflow-x: auto;
  color: #303030;
}
.hotel-combine-editor-content .hotel-attributes-attribute__value-map {
  height: 250px;
  margin-bottom: 10px;
  width: 100%;
}
.hotel-combine-editor-content .hotel-attributes-attribute__value input {
  margin-bottom: 10px;
}
.hotel-combine-editor-content .hotel-attributes-attribute__value textarea {
  margin-bottom: 10px;
  width: 880px;
}
.hotel-combine-editor-content__submit {
  float: left;
  margin: 0 10px 10px;
  padding-bottom: 10px;
}
.hotel-combine-editor-content__cancel {
  float: right;
  margin: 0 10px 10px;
  padding-bottom: 10px;
}

.combine-hotel-view__wrapper {
  display: inline-block;
  margin-left: auto;
}
.combine-hotel-view-content {
  color: #44474d;
}
.combine-hotel-view-content__submit {
  margin: 10px;
  float: left;
}
.combine-hotel-view-content__cancel {
  margin: 10px;
  float: right;
}

.search-for-best-match__wrapper {
  display: inline-block;
  margin-left: auto;
}
.search-for-best-match-content {
  color: #44474d;
}
.search-for-best-match-content__hotel {
  border: solid #dddddd 1px;
  margin-bottom: 20px;
  padding: 10px;
}
.search-for-best-match-content__hotel Button {
  margin-top: 10px;
}
.search-for-best-match-content__submit {
  margin: 10px;
  float: left;
}
.search-for-best-match-content__cancel {
  margin: 10px;
  float: right;
}

.language-selection .selectbox__field .react-selectize.root-node {
  width: 180px;
}
.language-selection .selectbox__field .react-selectize.root-node .react-selectize-control .resizable-input {
  caret-color: transparent;
  font-size: 1.2rem;
}

.dashboard {
  background: #f8f8f8;
  margin: -13px;
  min-height: calc(100vh - 54px);
}
.dashboard__header {
  font-size: 33px;
  padding: 20px 0 20px 60px;
  text-align: left;
}
.dashboard__cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: 45px;
}
.dashboard-card {
  align-items: center;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: #303030;
  color: black;
  display: flex;
  height: 100px;
  justify-content: space-between;
  margin: 10px 20px;
  max-width: 30%;
  text-decoration: none;
  width: 100%;
}
.dashboard-card:hover {
  box-shadow: 0 3px 6px rgba(48, 48, 48, 0.16);
  cursor: pointer;
}
@media only screen and (max-width: 991px) {
  .dashboard-card {
    max-width: 40%;
    margin: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .dashboard-card {
    max-width: 100%;
    margin: 10px;
  }
}
.dashboard-card__left, .dashboard-card__right {
  flex: 1 0 25%;
}
.dashboard-card__left svg {
  height: 60px;
}
.dashboard-card__middle {
  display: flex;
  flex: 2 0 50%;
  flex-direction: column;
  text-align: left;
}
.dashboard-card__middle--model-name {
  font-size: 16px;
  font-weight: bold;
}
.dashboard-card__middle--recent-entries, .dashboard-card__middle--last-modified-at {
  font-size: 12px;
}
.dashboard-card__middle--recent-entries--value, .dashboard-card__middle--last-modified-at--value {
  color: #428bca;
  font-weight: bold;
}
.dashboard-card__right {
  align-self: flex-end;
  font-size: 12px;
  margin-bottom: 28px;
}
.dashboard-card__right--total-entries--value {
  color: #428bca;
  font-weight: bold;
}

.hashtag-wrapper {
  margin-left: auto;
}
.hashtag-content__add-new {
  margin-top: 20px;
}
.hashtag-content__submit {
  margin-top: 10px;
}
.hashtag-content__list {
  margin-top: 20px;
  font-weight: normal;
}

.keyword-wrapper {
  margin-left: auto;
}
.keyword-content__add-new {
  margin-top: 20px;
}
.keyword-content__submit {
  margin-top: 10px;
}
.keyword-content__list {
  margin-top: 20px;
  font-weight: normal;
}

.human-verified-hotel {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.human-verified-hotel__container {
  margin-bottom: 10px;
  width: 48%;
}
.human-verified-hotel__container__hotel-card {
  border: solid #dddddd 1px;
  height: auto;
  min-height: 515px;
  margin-bottom: 10px;
  padding: 10px;
}

.hotel-information__map {
  height: 300px;
  width: 100%;
}
.hotel-information__header {
  display: flex;
  justify-content: space-between;
}
.hotel-information__field {
  margin: 8px 0 8px 0;
}
.hotel-information__field-attribute {
  color: #44474d;
  font-weight: bold;
  margin-right: 4px;
  display: inline;
}

.hotel-details--collapse {
  color: #ff6347;
  cursor: pointer;
}

.unmatched-human-verifiable-hotel {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.unmatched-human-verifiable-hotel__container {
  margin-bottom: 10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.unmatched-human-verifiable-hotel__container__hotel-card {
  border: solid #dddddd 1px;
  height: auto;
  min-height: 515px;
  margin-bottom: 10px;
  padding: 10px;
}

.faqs-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  text-align: left;
}
.faqs-list__pagination {
  display: flex;
  justify-content: flex-end;
}
.faqs-list__header {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 10px;
}
.faqs-list__header--no-padding {
  padding: 0;
}
.faqs-list__title {
  color: #333333;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 20px;
  text-align: left;
}
.faqs-list__data {
  border-top: 1px solid #e3e3e3;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  background: #f9f9f9;
}
.faqs-list__value {
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 42px;
  width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.faqs-list__value-title {
  text-transform: capitalize;
}
.faqs-list__value-topic {
  width: 800px;
  display: flex;
  margin-right: 15px;
}
.faqs-list__value-order {
  margin: 0 5px;
  font-weight: bold;
}
.faqs-list__value-icon {
  margin: auto 0 auto auto;
}
.faqs-list__value-icon .a {
  fill: black;
}
.faqs-list__value--actions {
  justify-content: center;
}
.faqs-list__table {
  border: 1px solid #e3e3e3;
  border-top: none;
}

.platform-feature-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  text-align: left;
}
.platform-feature-list__pagination {
  display: flex;
  justify-content: flex-end;
}
.platform-feature-list__header {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 10px;
}
.platform-feature-list__header--no-padding {
  padding: 0;
}
.platform-feature-list__title {
  color: #333333;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 20px;
  text-align: left;
}
.platform-feature-list__data {
  border-top: 1px solid #e3e3e3;
  background: #f9f9f9;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
}
.platform-feature-list__value {
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
  white-space: nowrap;
}
.platform-feature-list__value-title {
  text-transform: capitalize;
}
.platform-feature-list__value-topic {
  display: flex;
  margin-right: 15px;
  width: 750px;
}
.platform-feature-list__value-order {
  font-weight: bold;
  margin: 0 5px;
}
.platform-feature-list__value-icon {
  margin: auto 0 auto auto;
}
.platform-feature-list__value-icon .a {
  fill: black;
}
.platform-feature-list__value--actions {
  justify-content: center;
  width: 250px;
}
.platform-feature-list__table {
  border: 1px solid #e3e3e3;
  border-top: none;
}

.airline-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  text-align: left;
}
.airline-list__pagination {
  display: flex;
  justify-content: flex-end;
}
.airline-list__header {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 10px;
}
.airline-list__header--no-padding {
  padding: 0;
}
.airline-list__search-post-icon {
  right: 12px;
  top: -13px;
  width: 20px;
}
.airline-list__title {
  color: #333333;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 20px;
  text-align: left;
}
.airline-list__data {
  border-top: 1px solid #e3e3e3;
  display: flex;
  font-size: 14px;
  height: 42px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.airline-list__data-value {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
}
.airline-list__data-value-clickable {
  cursor: pointer;
  padding-left: 30px;
}
.airline-list__data-value-clickable-icon {
  font-size: 10px;
  margin-left: 10px;
}
.airline-list__data-value-clickable-icon svg {
  fill: #000000;
}
.airline-list__data-value--actions {
  justify-content: center;
  width: 350px;
}
.airline-list__data-value--nested-actions {
  justify-content: flex-end;
  width: 350px;
}
.airline-list__data-value--nested-actions-wide {
  justify-content: flex-end;
  width: 450px;
}
.airline-list__data--nested {
  align-items: center;
  padding-left: 25px;
}
.airline-list__data:nth-child(odd) {
  background: #f9f9f9;
}
.airline-list__value {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  max-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
}
.airline-list__value-clickable {
  cursor: pointer;
}
.airline-list__value-clickable-icon {
  font-size: 12px;
  margin-left: 4px;
}
.airline-list__value-clickable-icon:last-of-type {
  margin-left: 0;
}
.airline-list__value-clickable-icon svg {
  fill: #888c9d;
}
.airline-list__value-clickable-icon--active svg {
  fill: #000000;
}
.airline-list__value-title {
  text-transform: capitalize;
}
.airline-list__value--actions {
  justify-content: center;
  width: 350px;
}
.airline-list__table {
  border: 1px solid #e3e3e3;
  border-top: none;
}
.airline-list__modal-wrapper {
  padding: 0 12px 22px;
}
.airline-list__modal-fare-brand {
  padding-top: 10px;
}

.airline-form__description .text-field__box {
  padding-left: 0;
  padding-right: 0;
}
.airline-form__cabin-class .select-box__width--small .select-box__control {
  width: 100%;
}

.fare-brand-form {
  margin: 10px;
  padding: 10px;
}
.fare-brand-form__error-message {
  border-top: 1px solid #a9a9a9;
  padding-top: 10px;
}
.fare-brand-form__container {
  display: flex;
  padding-top: 10px;
}
.fare-brand-form__container-segment {
  margin-bottom: 40px;
}
.fare-brand-form__container-segment-title {
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}
.fare-brand-form__container-segment-field {
  font-size: 14px;
  text-align: left;
}
.fare-brand-form__container-segment-field-radio-title {
  align-items: center;
  color: #44474d;
  display: flex;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.fare-brand-form__container-segment-field-multi-select-box {
  text-align: left;
}
.fare-brand-form__container-segment-field-multi-select-box .select-box__label {
  display: block;
  margin-bottom: 5px;
}
.fare-brand-form__container-segment-field .label-hint {
  cursor: pointer;
  display: inline-block;
  height: 1.6rem;
  margin-left: 0.6rem;
  position: relative;
  width: 1.6rem;
}
.fare-brand-form__title {
  color: #333333;
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 20px;
  text-align: left;
}
.fare-brand-form__header {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  margin: 0 auto 5px;
  text-align: left;
  width: 65%;
}
.fare-brand-form__submit-button {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 40%;
}
.fare-brand-form__submit-button-loading {
  align-items: center;
  display: flex;
}

.fare-brand-show {
  border-top: 1px solid #a9a9a9;
  display: flex;
  padding-top: 10px;
}
.fare-brand-show__submit-button-button {
  margin-top: 20px;
}
.fare-brand-show__supplier-brand-name {
  display: flex;
  padding-top: 13px;
}
.fare-brand-show__segment {
  margin-bottom: 30px;
}
.fare-brand-show__segment-title {
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 20px;
  text-align: left;
}
.fare-brand-show__segment-infos--field {
  background: #5bc0de;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  width: fit-content;
}
.fare-brand-show__segment-infos--value {
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  font-size: 16px;
  overflow-x: auto;
  padding: 5px 10px;
}

.empty-content-placeholder {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;
}
.empty-content-placeholder__icon {
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 50%;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;
}
.empty-content-placeholder__text {
  color: #888c9d;
  font-size: 14px;
  font-weight: bolder;
  padding-top: 10px;
}

.flight-form__airline .react-selectize.default.root-node .react-selectize-control .react-selectize-search-field-and-selected-values {
  height: 80%;
  max-width: 90%;
  padding-top: 5px;
}
.flight-form__airline .selectbox__field .react-selectize.root-node .react-selectize-control .value-wrapper {
  max-width: 100%;
}
.flight-form__airline .simple-select.react-selectize.root-node .simple-value {
  max-width: 100%;
}
.flight-form__airline .simple-select.react-selectize.root-node .simple-value span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.travel-news-form__country .simple-select.react-selectize.root-node .simple-value span {
  overflow: hidden;
  padding-top: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 230px;
}
.travel-news-form__country .simple-select.react-selectize.root-node .react-selectize-search-field-and-selected-values {
  flex-wrap: nowrap;
}
.travel-news-form__hashtags .select-box__width--small .select-box__control {
  width: 100%;
}

.airport-form__description .text-field__box {
  padding-left: 0;
  padding-right: 0;
}

.ancillary-service-form__full-description .text-field__box, .ancillary-service-form__description .text-field__box {
  padding-left: 0;
  padding-right: 0;
}

.country-form__description .text-field__box {
  padding-left: 0;
  padding-right: 0;
}

.country-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
}
.country-list__pagination {
  display: flex;
  justify-content: flex-end;
}
.country-list__table {
  border: 1px solid #e3e3e3;
  border-top: none;
}
.country-list__header {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 10px;
}
.country-list__data {
  border-top: 1px solid #e3e3e3;
  display: flex;
  font-size: 14px;
  height: 42px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.country-list__data-value {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 12.33%;
}
.country-list__data-value-clickable {
  cursor: pointer;
}
.country-list__data-value-clickable-icon {
  font-size: 10px;
  margin-left: 20px;
}
.country-list__data-value-clickable-icon svg {
  fill: #000000;
}
.country-list__data-value--actions {
  justify-content: flex-end;
  width: 20%;
}
.country-list__data-value--header-actions {
  justify-content: center;
  width: 20%;
}
.country-list__data--nested {
  justify-content: flex-start;
  padding-left: 30px;
}
.country-list__data:nth-child(odd) {
  background: #f9f9f9;
}

.welltravel_news-form__hashtags .select-box__width--small .select-box__control {
  width: 100%;
}

.faq-form__hashtags .select-box__width--large .select-box__control {
  width: 100%;
}

.city-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
}
.city-list__data {
  border-top: 1px solid #e3e3e3;
  display: flex;
  font-size: 14px;
  height: 42px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.city-list__data-value {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 33.3333%;
}
.city-list__data-value-clickable {
  cursor: pointer;
}
.city-list__data-value-clickable-icon {
  font-size: 10px;
  margin-left: 20px;
}
.city-list__data-value-clickable-icon svg {
  fill: #000000;
}
.city-list__data-value--actions {
  justify-content: flex-end;
  width: 230px;
}
.city-list__data--nested {
  justify-content: flex-start;
  padding-left: 30px;
}
.city-list__data:nth-child(odd) {
  background: #f9f9f9;
}

.name-translation-form__supplier-brand-name {
  display: flex;
  padding-top: 13px;
}
.name-translation-form__container {
  display: flex;
  padding-top: 10px;
}
.name-translation-form__container-segment {
  margin-bottom: 40px;
}
.name-translation-form__container-segment-field {
  font-size: 14px;
  text-align: left;
}
.name-translation-form__submit-button {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  width: 40%;
}

.r-tabs--small .r-tabs__tab {
  font-size: 1.2rem;
}
.r-tabs--large .r-tabs__tab {
  padding: 0.8rem 2.5rem;
}
.r-tabs__tab-list {
  border-bottom: 0.1rem solid #e2e2e2;
  display: flex;
  flex-flow: row wrap;
  font-size: 1.6rem;
}
.r-tabs__tab {
  background: #f9f9f9;
  border: 0.1rem solid #e2e2e2;
  border-left: none;
  color: #5e5e5e;
  cursor: pointer;
  font-weight: 300;
  margin: 0 0 -0.1rem;
  padding: 0.6rem 2.5rem;
}
.r-tabs__tab:hover {
  background: #dddddd;
  color: #474747;
}
.r-tabs__tab:active {
  background: #474747;
  color: rgb(255, 255, 255);
}
.r-tabs__tab--selected {
  background: rgb(255, 255, 255);
  border-bottom: 0.1rem solid rgb(255, 255, 255);
  color: #303030;
  font-weight: normal;
  outline: none;
  pointer-events: none;
}
.r-tabs__tab--disabled {
  background: #eeeeee;
  color: #c6c6c6;
  pointer-events: none;
}
.r-tabs__tab-panel {
  display: none;
  font-size: 1.6rem;
  padding: 2rem 2.5rem;
}
.r-tabs__tab-panel--selected {
  display: block;
}
.r-tabs__tab-panel .r-tabs__tab-list, .r-tabs__tab-panel .r-tabs__tab-panel {
  margin: 0 -2.5rem;
}
.r-tabs-dark .r-tabs__tab-list {
  border-bottom: 0.1rem solid #303030;
}
.r-tabs-dark .r-tabs__tab {
  background: #3b3b3b;
  border: 0.1rem solid #303030;
  border-left: none;
  color: #c6c6c6;
}
.r-tabs-dark .r-tabs__tab:hover {
  background: #2c2c2c;
}
.r-tabs-dark .r-tabs__tab:active {
  background: #e2e2e2;
  color: #474747;
}
.r-tabs-dark .r-tabs__tab--selected {
  background: #474747;
  border-bottom: 0.1rem solid #474747;
  color: #e2e2e2;
}
.r-tabs-dark .r-tabs__tab--disabled {
  background: #525252;
  color: rgba(198, 198, 198, 0.2);
}
.r-tabs-dark .r-tabs__tab-panel {
  background: #474747;
  color: #e2e2e2;
}

.select-box__label, .select-box__error {
  color: #303030;
}
.select-box__label--tiny, .select-box__error--tiny {
  font-size: 12px;
}
.select-box__label--small, .select-box__error--small {
  font-size: 14px;
}
.select-box__label--large, .select-box__error--large {
  font-size: 16px;
}
.select-box__width--tiny .select-box__control {
  width: 150px;
}
.select-box__width--tiny .select-box__menu {
  width: 150px;
}
.select-box__width--small .select-box__control {
  width: 300px;
}
.select-box__width--small .select-box__menu {
  width: 300px;
}
.select-box__width--large .select-box__control {
  width: 450px;
}
.select-box__width--large .select-box__menu {
  width: 450px;
}
.select-box-container .select-box__control {
  min-height: 24px;
}
.select-box-container .select-box__option {
  align-items: center;
  display: flex;
}
.select-box-container--notMulti.select-box-container--tiny .select-box__indicators {
  height: 24px;
}
.select-box-container--notMulti.select-box-container--small .select-box__indicators {
  height: 32px;
}
.select-box-container--notMulti.select-box-container--large .select-box__indicators {
  height: 40px;
}
.select-box-container .select-box__multi-value__remove:hover {
  background-color: #303030;
  color: rgb(255, 255, 255);
}
.select-box-container--tiny .select-box__control,
.select-box-container--tiny .select-box__indicators,
.select-box-container--tiny .select-box__option {
  font-size: 12px;
  min-height: 24px;
}
.select-box-container--tiny .select-box__value-container {
  padding: 0 8px;
}
.select-box-container--small .select-box__control,
.select-box-container--small .select-box__option {
  font-size: 14px;
  min-height: 32px;
}
.select-box-container--large .select-box__control,
.select-box-container--large .select-box__option {
  font-size: 16px;
  min-height: 40px;
}
.select-box-container--error .select-box__control {
  border-color: #ff605d;
}
.select-box__error {
  font-size: 14px;
}
.select-box__pre-icon + .select-box__placeholder-text--tiny {
  margin-left: 17px;
}
.select-box__pre-icon + .select-box__placeholder-text--small {
  margin-left: 20px;
}
.select-box__pre-icon + .select-box__placeholder-text--large {
  margin-left: 25px;
}
.select-box__pre-icon {
  height: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
}
.select-box__pre-icon svg {
  fill: #474747;
  height: 100%;
  width: 100%;
}
.select-box__pre-icon--small {
  height: 16px;
  width: 16px;
}
.select-box__pre-icon--tiny {
  height: 14px;
  width: 14px;
}
.select-box__pre-icon--large {
  height: 20px;
  width: 20px;
}

.base-modal {
  align-items: center;
  bottom: 0;
  color: #474747;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  transition: 0.2s 0.2s;
  visibility: hidden;
  z-index: 30;
}
.base-modal--small .base-modal__container {
  max-width: 30.4rem;
}
.base-modal--large .base-modal__container {
  max-width: 70.4rem;
}
.base-modal--huge .base-modal__container {
  max-width: 96.8rem;
}
.base-modal--square .base-modal__container, .base-modal--full .base-modal__container {
  height: 100%;
  max-height: 100%;
}
.base-modal--square .base-modal__content, .base-modal--full .base-modal__content {
  border-radius: 0;
  max-height: 100vh;
}
.base-modal--square .base-modal__close, .base-modal--full .base-modal__close {
  right: 1rem;
  top: 1rem;
}
.base-modal--full .base-modal__close {
  right: -38px;
  top: 5px;
}
.base-modal__hero-icon {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #f1f1f1;
  border-radius: 50%;
  display: inline-block;
  height: 65px;
  margin: 15px 0 30px;
  padding: 15px;
  width: 65px;
}
.base-modal__hero-icon svg {
  fill: #474747;
  height: 100%;
  width: 100%;
}
.base-modal__button {
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
}
.base-modal__text svg, .base-modal__text--small-svg svg {
  display: inline-block;
  height: 30px;
  position: relative;
  top: 4px;
  width: 30px;
}
.base-modal--default-padding {
  padding: 1rem;
}
.base-modal__wrapper {
  display: inline-block;
}
.base-modal__wrapper input[type=checkbox].base-modal__state {
  display: none;
}
.base-modal__container {
  display: flex;
  max-height: 80%;
  max-width: 50.4rem;
  opacity: 0;
  position: relative;
  transition: 0.2s 0.1s;
  width: 80%;
  z-index: 100;
}
.base-modal__content {
  background: rgb(255, 255, 255);
  border-radius: 0.8rem;
  line-height: normal;
  max-height: 80vh;
  overflow: auto;
  width: 100%;
}
.base-modal__content .button-field {
  align-items: center;
  background: #f9f9f9;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 16px;
}
.base-modal__header {
  border-bottom: 1px solid #e2e2e2;
  font-size: 2.2rem;
  font-weight: 500;
  padding: 10px 16px;
}
.base-modal__body {
  flex-basis: 100%;
}
.base-modal__body .actions {
  margin: 20px 0 0;
}
.base-modal__action {
  margin: 20px 0 0;
}
.base-modal__action .button {
  display: inline-block;
}
.base-modal__close {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #525252;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  height: 3rem;
  padding: 0.7rem;
  position: absolute;
  right: -3rem;
  top: -3rem;
  width: 3rem;
}
.base-modal__close svg {
  fill: #e2e2e2;
  width: 100%;
}
.base-modal__link {
  cursor: pointer;
  display: inline-block;
}
.base-modal__link--disabled {
  opacity: 0.4;
  pointer-events: none;
}
.base-modal__background {
  background: rgba(71, 71, 71, 0.75);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}
.base-modal__background:hover ~ .base-modal__container .base-modal__close {
  background: #000000;
}
.base-modal__state {
  display: none;
}
.base-modal__state:checked ~ .base-modal {
  opacity: 1;
  transition: 0.2s;
  visibility: visible;
}
.base-modal__state:checked ~ .base-modal .base-modal__container {
  opacity: 1;
}
.base-modal--split {
  text-align: center;
}
.base-modal--split .base-modal__body {
  display: flex;
}
.base-modal--dark .base-modal__background {
  background: rgba(48, 48, 48, 0.75);
}
.base-modal--dark .base-modal__content {
  background: #474747;
  color: #c6c6c6;
}
.base-modal--dark .base-modal__close {
  background: #474747;
}
.base-modal--dark .base-modal__hero-icon {
  background: #303030;
}
.base-modal--dark .base-modal__hero-icon svg {
  fill: #f1f1f1;
}

.leaflet-top,
.leaflet-bottom,
.location__map .location__share {
  z-index: 1;
}

/* eslint-disable jsx-a11y/label-has-associated-control */
.radio {
  align-items: center;
  color: #474747;
  display: inline-flex;
  font-size: 1.6rem;
  margin: 0 1.6rem 0 0;
}
.radio:last-of-type, .radio:only-of-type {
  margin: 0;
}
.radio__input {
  display: none;
}
.radio__input:checked + .radio__check::before {
  background: #5e5e5e;
  height: 60%;
  width: 60%;
}
.radio__check {
  border: 0.2rem solid #5e5e5e;
  border-radius: 50%;
  display: inline-block;
  height: 1.8rem;
  overflow: hidden;
  position: relative;
  transition: 0.2s;
  width: 1.8rem;
}
.radio__check::before {
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s;
  width: 100%;
}
.radio__label {
  margin: 0 0 0 0.8rem;
}
.radio--small {
  font-size: 1.2rem;
}
.radio--small .radio__check {
  height: 1.6rem;
  width: 1.6rem;
}
.radio--large {
  font-size: 1.8rem;
}
.radio--large .radio__check {
  height: 2.2rem;
  width: 2.2rem;
}
.radio--huge {
  font-size: 2.2rem;
}
.radio--huge .radio__check {
  height: 2.4rem;
  width: 2.4rem;
}
.radio--large .radio__label, .radio--huge .radio__label {
  margin: 0 0 0 1.2rem;
}
.radio-dark {
  color: #c6c6c6;
}
.radio-dark .radio__check {
  border-color: #c6c6c6;
}
.radio-dark .radio__input:checked + .radio__check::before {
  background: #c6c6c6;
}
.radio--disabled {
  opacity: 0.2;
  pointer-events: none;
}

.badge {
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.badge--active {
  background: #3ab974;
}
.badge--inactive {
  background: #d0d0d0;
}
.badge--new {
  background: #e04e3f;
}
.badge--small {
  height: 12px;
  width: 12px;
}
.badge--normal {
  height: 24px;
  width: 24px;
}
.badge--number {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.icon-button {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  background: transparent;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  height: 3.2rem;
  justify-content: center;
  margin: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding: 0.6rem;
  position: relative;
  width: 3.2rem;
}
.icon-button--tertiary .icon-button__icon svg {
  fill: #4b73a7;
}
.icon-button--tertiary:hover {
  background: #e2e8f1;
}
.icon-button--tertiary:hover .icon-button__label {
  opacity: 1;
  visibility: visible;
}
.icon-button--tertiary:hover .icon-button__icon svg {
  fill: #1c355d;
}
.icon-button--tertiary:focus {
  outline: none;
}
.icon-button--tertiary:active {
  background: rgb(255, 255, 255);
}
.icon-button--tertiary:active .icon-button__icon {
  fill: #4b73a7;
}
.icon-button--tertiary:active .icon-button__icon svg {
  fill: #4b73a7;
}
.icon-button--v2 {
  background: transparent;
}
.icon-button--v2:active {
  background: transparent;
}
.icon-button--icon-only {
  background: transparent;
  border: none;
}
.icon-button--icon-only:hover {
  background: transparent;
}
.icon-button--icon-only:active {
  background: transparent;
  border: none;
}
.icon-button--icon-only:active .icon-button__icon {
  fill: #474747;
}
.icon-button--icon-only:active .icon-button__icon svg {
  fill: #474747;
}
.icon-button .icon-button__badge {
  height: 8px;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 8px;
}
.icon-button--tiny {
  height: 2.4rem;
  margin: 0.2rem;
  padding: 0.4rem;
  width: 2.4rem;
}
.icon-button--tiny .icon-button__badge {
  right: 0;
  top: 0;
}
.icon-button--small {
  height: 2.8rem;
  padding: 0.5rem;
  width: 2.8rem;
}
.icon-button--small .icon-button__badge {
  right: 2px;
  top: 2px;
}
.icon-button--large {
  height: 3.6rem;
  margin: 0.4rem;
  padding: 0.7rem;
  width: 3.6rem;
}
.icon-button--large .icon-button__badge {
  right: 8px;
  top: 8px;
}
.icon-button--huge {
  height: 5.2rem;
  margin: 0.4rem;
  padding: 1.3rem;
  width: 5.2rem;
}
.icon-button--huge .icon-button__badge {
  right: 21px;
  top: 21px;
}
.icon-button__label {
  background: #525252;
  border-radius: 0.3rem;
  color: #e2e2e2;
  font-size: 1.2rem;
  height: 2rem;
  left: 50%;
  line-height: 1.5em;
  opacity: 0;
  padding: 0.1rem 0.6rem;
  position: absolute;
  top: calc(100% + 0.5rem);
  transform: translateX(-50%);
  transition: 0.3s 0.2s;
  visibility: hidden;
  white-space: nowrap;
  z-index: 1;
}
.icon-button__label--right, .icon-button__label--left {
  left: auto;
  top: 50%;
  transform: translate(0, -50%);
}
.icon-button__label--right {
  left: calc(100% + 0.5rem);
}
.icon-button__label--left {
  right: calc(100% + 0.5rem);
}
.icon-button__label--top {
  bottom: calc(100% + 0.5rem);
  top: auto;
}
.icon-button__icon {
  align-items: center;
  display: flex;
  font-size: 30px;
  justify-content: center;
}
.icon-button__icon svg {
  fill: #474747;
}
.icon-button-dark {
  background: #393939;
  border-color: #2c2c2c;
}
.icon-button-dark .icon-button__label {
  background: #e2e2e2;
  color: #000000;
}
.icon-button-dark .icon-button__icon svg {
  fill: #c6c6c6;
}
.icon-button-dark:hover {
  background: #2c2c2c;
  border-color: #2c2c2c;
}
.icon-button-dark:focus {
  outline: none;
}
.icon-button-dark:active {
  background: #f1f1f1;
  border: 0.1rem solid #f1f1f1;
}
.icon-button-dark:active .icon-button__icon {
  fill: #474747;
}
.icon-button--action {
  background: rgba(255, 96, 93, 0.15);
  border: 0.1rem solid rgba(255, 96, 93, 0.3);
}
.icon-button--action .icon-button__icon svg {
  fill: #ff605d;
}
.icon-button--action:hover {
  background: rgba(255, 96, 93, 0.3);
  border: 0.1rem solid rgba(255, 96, 93, 0.3);
}
.icon-button--action:hover .icon-button__icon {
  fill: #ff605d;
}
.icon-button--action:focus {
  outline: none;
}
.icon-button--action:active {
  background: #ff605d;
  border: 0.1rem solid #ff605d;
}
.icon-button--action:active .icon-button__icon {
  fill: rgb(255, 255, 255);
}
.icon-button--tertiary-inverse {
  background: #4b73a7;
}
.icon-button--tertiary-inverse .icon-button__icon svg {
  fill: #e2e8f1;
}
.icon-button--tertiary-inverse:hover {
  background: #4b73a7;
}
.icon-button--tertiary-inverse:hover .icon-button__icon svg {
  fill: #e2e8f1;
}
.icon-button--tertiary-inverse:focus {
  outline: none;
}
.icon-button--tertiary-inverse:active {
  background: #4b73a7;
}
.icon-button--tertiary-inverse:active .icon-button__icon {
  fill: #e2e8f1;
}
.icon-button--standalone {
  background: none;
  border: 0.1rem solid transparent;
}
.icon-button--standalone .icon-button__icon {
  fill: #919191;
}
.icon-button--standalone.icon-button--action .icon-button__icon {
  fill: #ff605d;
}
.icon-button--standalone.icon-button--action:active .icon-button__icon {
  fill: rgb(255, 255, 255);
}
.icon-button--standalone.icon-button--v2:hover {
  background: rgba(0, 0, 0, 0.14);
}
.icon-button--standalone.icon-button--v2:active {
  background: rgba(0, 0, 0, 0.28);
}
.icon-button--disabled {
  border: 0.1rem solid transparent;
  opacity: 0.4;
  pointer-events: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .icon-button__icon svg {
    width: 4rem;
    height: 4rem;
  }

  .cart-item__remove-button .icon-button__icon {
    height: 2rem;
  }

  .icon-button--action .icon-button__icon {
    width: 3rem;
    height: 3rem;
  }
}
.checkbox {
  align-items: center;
  color: #474747;
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  margin: 0 16px 0 0;
}
.checkbox:last-of-type, .checkbox:only-of-type {
  margin: 0;
}
.checkbox__input {
  display: none;
}
.checkbox__input:checked + .checkbox__check {
  background: #5e5e5e;
}
.checkbox__input:checked + .checkbox__check::before, .checkbox__input:checked + .checkbox__check::after {
  opacity: 1;
}
.checkbox__input:checked + .checkbox__check::before {
  transform: rotate(50deg);
}
.checkbox__input:checked + .checkbox__check::after {
  transform: rotate(-50deg);
}
.checkbox__check {
  border: 2px solid #5e5e5e;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-block;
  flex-basis: 20px;
  height: 20px;
  overflow: hidden;
  position: relative;
  transition: 0.2s;
  width: 20px;
}
.checkbox__check::before, .checkbox__check::after {
  background: rgb(255, 255, 255);
  content: "";
  height: 2px;
  opacity: 0;
  position: absolute;
  transition: 0.2s;
}
.checkbox__check::before {
  left: 1px;
  top: 50%;
  width: 6px;
}
.checkbox__check::after {
  left: 3px;
  top: 45%;
  width: 12px;
}
.checkbox__label {
  flex: 1 0 auto;
  margin: 0 0 0 8px;
}
.checkbox--small {
  font-size: 14px;
}
.checkbox--small .checkbox__label {
  margin-left: 8px;
}
.checkbox--small .checkbox__check {
  flex-basis: 16px;
  height: 16px;
  width: 16px;
}
.checkbox--small .checkbox__check::before {
  width: 5px;
}
.checkbox--small .checkbox__check::after {
  left: 3px;
  top: 45%;
  width: 9px;
}
.checkbox--small .label-hint {
  font-size: 14px;
  margin-left: 7px;
}
.checkbox--large {
  font-size: 16px;
}
.checkbox--large .checkbox__label {
  margin-left: 12px;
}
.checkbox--large .checkbox__check {
  flex-basis: 22px;
  height: 22px;
  width: 22px;
}
.checkbox--large .checkbox__check::before {
  left: 0;
  top: 56%;
  width: 7px;
}
.checkbox--large .checkbox__check::after {
  width: 16px;
}
.checkbox--large .label-hint {
  margin-left: 9px;
}
.checkbox--huge {
  font-size: 20px;
}
.checkbox--huge .checkbox__label {
  margin-left: 14px;
}
.checkbox--huge .checkbox__check {
  flex-basis: 24px;
  height: 24px;
  width: 24px;
}
.checkbox--huge .checkbox__check::before {
  left: 0;
  top: 60%;
  width: 8px;
}
.checkbox--huge .checkbox__check::after {
  width: 18px;
}
.checkbox--huge .label-hint {
  margin-left: 9px;
}
.checkbox-dark {
  color: #c6c6c6;
}
.checkbox-dark .checkbox__check {
  border-color: #c6c6c6;
}
.checkbox-dark .checkbox__input:checked + .checkbox__check {
  background: #c6c6c6;
}
.checkbox-dark .checkbox__input:checked + .checkbox__check::before, .checkbox-dark .checkbox__input:checked + .checkbox__check::after {
  background: #474747;
}
.checkbox-dark.checkbox--disabled .checkbox__check {
  background: #c6c6c6;
}
.checkbox-dark.checkbox--disabled .checkbox__check::before {
  background: #474747;
}
.checkbox--disabled {
  opacity: 0.2;
  pointer-events: none;
}
.checkbox--disabled .checkbox__input:not(:checked) + .checkbox__check {
  background: #5e5e5e;
}
.checkbox--disabled .checkbox__input:not(:checked) + .checkbox__check::before {
  left: 50%;
  opacity: 1;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}
.checkbox .label-hint svg {
  fill: #cbcbcb;
}

.label-hint {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 0;
  position: relative;
  width: auto;
}
.label-hint:hover .label-hint__popup {
  opacity: 1;
  visibility: visible;
}
.label-hint > svg {
  fill: #474747;
}
.label-hint__popup {
  background: #5e5e5e;
  border-radius: 0.3rem;
  bottom: 105%;
  box-shadow: 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  color: #e2e2e2;
  font-size: 1.2rem;
  line-height: 1.4em;
  opacity: 0;
  padding: 1.5rem 2rem;
  position: absolute;
  transition: 0.2s;
  visibility: hidden;
  min-width: 25rem;
  z-index: 4;
}
.label-hint__popup--width-auto {
  min-width: auto;
}
.label-hint__popup--top-right {
  left: 105%;
}
.label-hint__popup--top-left {
  right: 105%;
}
.label-hint--tiny {
  height: 1.2rem;
  width: 1.2rem;
}
.label-hint--small {
  height: 1.4rem;
  width: 1.4rem;
}
.label-hint--large {
  height: 1.8rem;
  width: 1.8rem;
}
.label-hint--huge {
  height: 2rem;
  width: 2rem;
}
.label-hint--no-margin {
  margin: 0;
}

.common-form {
  margin: 10px;
  padding: 10px;
}
.common-form__previous-image {
  display: block;
  margin: 0 auto;
  padding-bottom: 10px;
  width: 480px;
}
.common-form__previous-image img {
  display: block;
  height: auto;
  max-width: 100%;
}
.common-form__map {
  height: 400px;
  margin-bottom: 20px;
  width: 100%;
}
.common-form__container {
  padding-top: 10px;
  display: flex;
  text-align: left;
}
.common-form__error-message {
  border-top: 1px solid #a9a9a9;
  padding-top: 10px;
}
.common-form__title {
  color: #333333;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 20px;
  text-align: left;
}
.common-form__header {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  margin: 0 auto 5px auto;
  text-align: left;
  width: 65%;
}
.common-form__field {
  padding-bottom: 20px;
}
.common-form__field-multi-select-box {
  text-align: left;
}
.common-form__field-multi-select-box .select-box__label {
  display: block;
  margin-bottom: 5px;
}
.common-form__field .label-hint {
  cursor: pointer;
  display: inline-block;
  height: 1.6rem;
  margin-left: 0.6rem;
  position: relative;
  width: 1.6rem;
}
.common-form__field .text-field__box {
  max-width: 100%;
  min-height: 60px;
  min-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.common-form__submit-button {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  width: 40%;
}
.common-form__submit-button-loading {
  align-items: center;
  display: flex;
}
.common-form__submit-button--with-margin {
  margin: 20px;
}
.common-form__submit-button--with-left-padding {
  padding-left: 20;
}
.common-show {
  border-top: 1px solid darkgrey;
  display: flex;
  padding-top: 10px;
}
.common-show__map {
  height: 400px;
  margin-bottom: 20px;
}
.common-show__infos {
  display: flex;
  flex-direction: column;
  padding-right: 5px;
  width: 50%;
}
.common-show__infos--field {
  background: #5bc0de;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 8px;
  padding: 5px;
  width: fit-content;
}
.common-show__infos--value {
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 20px;
  overflow-wrap: break-word;
  overflow-x: auto;
  padding: 5px 10px;
}
.common-show__images {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  width: 50%;
}

.sanitized-html img {
  width: 100%;
}

.lds-dual-ring {
  display: block;
  height: 40px;
  margin: 100px auto;
  padding: 20px;
  width: 40px;
}

.lds-dual-ring:after {
  animation: lds-dual-ring 1.2s linear infinite;
  border: 4px solid #428bca;
  border-color: #428bca transparent #428bca transparent;
  border-radius: 50%;
  content: " ";
  display: block;
  height: 30px;
  margin: 1px;
  width: 30px;
}

.dashboard-card__middle--model-name {
  margin-bottom: 10px;
}

.resizable-input {
  color: #44474d;
}

.react-selectize-search-field-and-selected-values {
  font-size: 1.6rem;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:root {
  --reflex-columns: 12;
  --reflex-grid-spacing: 15px;
  --reflex-xs: 576px;
  --reflex-sm: 768px;
  --reflex-md: 992px;
  --reflex-lg: 1200px;
  --reflex-xlg: 1600px;
  --reflex-xxs-max: 575px;
  --reflex-xs-max: 767px;
  --reflex-sm-max: 991px;
  --reflex-md-max: 1199px;
  --reflex-lg-max: 1599px;
}

.container,
.container-full {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}
.container .grid,
.container-full .grid {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}

.grid {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  position: relative;
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  word-spacing: -0.43em;
  list-style-type: none;
}
.grid::before, .grid::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}

[class*=col-] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
  position: relative;
  width: 100%;
  vertical-align: top;
  padding: 15px;
  display: inline-block;
  *display: inline;
  zoom: 1;
}
[class*=col-]::before, [class*=col-]::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}
[class*=col-] .grid {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: -15px;
}

.col-12 {
  width: 100%;
  *width: 99.9%;
}

.col-11 {
  width: 91.6666666667%;
  *width: 91.5666666667%;
}

.col-10 {
  width: 83.3333333333%;
  *width: 83.2333333333%;
}

.col-9 {
  width: 75%;
  *width: 74.9%;
}

.col-8 {
  width: 66.6666666667%;
  *width: 66.5666666667%;
}

.col-7 {
  width: 58.3333333333%;
  *width: 58.2333333333%;
}

.col-6 {
  width: 50%;
  *width: 49.9%;
}

.col-5 {
  width: 41.6666666667%;
  *width: 41.5666666667%;
}

.col-4 {
  width: 33.3333333333%;
  *width: 33.2333333333%;
}

.col-3 {
  width: 25%;
  *width: 24.9%;
}

.col-2 {
  width: 16.6666666667%;
  *width: 16.5666666667%;
}

.col-1 {
  width: 8.3333333333%;
  *width: 8.2333333333%;
}

@media (min-width: 576px) {
  .col-xs-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-xs-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .col-xs-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .col-xs-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-xs-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .col-xs-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .col-xs-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-xs-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .col-xs-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .col-xs-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-xs-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .col-xs-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 768px) {
  .col-sm-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-sm-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .col-sm-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .col-sm-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-sm-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .col-sm-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .col-sm-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-sm-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .col-sm-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .col-sm-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-sm-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .col-sm-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 992px) {
  .col-md-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-md-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .col-md-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .col-md-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-md-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .col-md-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .col-md-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-md-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .col-md-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .col-md-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-md-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .col-md-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 1200px) {
  .col-lg-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-lg-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .col-lg-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .col-lg-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-lg-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .col-lg-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .col-lg-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-lg-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .col-lg-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .col-lg-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-lg-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .col-lg-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 1600px) {
  .col-xlg-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-xlg-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .col-xlg-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .col-xlg-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-xlg-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .col-xlg-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .col-xlg-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-xlg-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .col-xlg-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .col-xlg-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-xlg-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .col-xlg-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
.col-auto {
  -ms-flex: 1 0 0px;
  -webkit-flex: 1 0 0px;
  flex: 1 0 0px;
  width: auto;
}

@media (min-width: 576px) {
  .col-xs-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 768px) {
  .col-sm-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 992px) {
  .col-md-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 1200px) {
  .col-lg-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
@media (min-width: 1600px) {
  .col-xlg-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}
.order-12 {
  -ms-flex-order: 12;
  -webkit-order: 12;
  order: 12;
}

.order-11 {
  -ms-flex-order: 11;
  -webkit-order: 11;
  order: 11;
}

.order-10 {
  -ms-flex-order: 10;
  -webkit-order: 10;
  order: 10;
}

.order-9 {
  -ms-flex-order: 9;
  -webkit-order: 9;
  order: 9;
}

.order-8 {
  -ms-flex-order: 8;
  -webkit-order: 8;
  order: 8;
}

.order-7 {
  -ms-flex-order: 7;
  -webkit-order: 7;
  order: 7;
}

.order-6 {
  -ms-flex-order: 6;
  -webkit-order: 6;
  order: 6;
}

.order-5 {
  -ms-flex-order: 5;
  -webkit-order: 5;
  order: 5;
}

.order-4 {
  -ms-flex-order: 4;
  -webkit-order: 4;
  order: 4;
}

.order-3 {
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}

.order-2 {
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}

.order-1 {
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}

.order-0 {
  -ms-flex-order: 0;
  -webkit-order: 0;
  order: 0;
}

@media (min-width: 576px) {
  .order-xs-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .order-xs-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .order-xs-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .order-xs-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .order-xs-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .order-xs-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .order-xs-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .order-xs-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .order-xs-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .order-xs-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .order-xs-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .order-xs-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .order-xs-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media (min-width: 768px) {
  .order-sm-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media (min-width: 992px) {
  .order-md-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .order-md-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .order-md-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .order-md-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .order-md-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .order-md-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .order-md-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .order-md-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .order-md-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .order-md-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .order-md-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .order-md-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .order-md-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media (min-width: 1200px) {
  .order-lg-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media (min-width: 1600px) {
  .order-xlg-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .order-xlg-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .order-xlg-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .order-xlg-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .order-xlg-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .order-xlg-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .order-xlg-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .order-xlg-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .order-xlg-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .order-xlg-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .order-xlg-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .order-xlg-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .order-xlg-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
.offset-11 {
  margin-left: 91.6666666667%;
  *margin-left: 91.5666666667%;
}

.offset-10 {
  margin-left: 83.3333333333%;
  *margin-left: 83.2333333333%;
}

.offset-9 {
  margin-left: 75%;
  *margin-left: 74.9%;
}

.offset-8 {
  margin-left: 66.6666666667%;
  *margin-left: 66.5666666667%;
}

.offset-7 {
  margin-left: 58.3333333333%;
  *margin-left: 58.2333333333%;
}

.offset-6 {
  margin-left: 50%;
  *margin-left: 49.9%;
}

.offset-5 {
  margin-left: 41.6666666667%;
  *margin-left: 41.5666666667%;
}

.offset-4 {
  margin-left: 33.3333333333%;
  *margin-left: 33.2333333333%;
}

.offset-3 {
  margin-left: 25%;
  *margin-left: 24.9%;
}

.offset-2 {
  margin-left: 16.6666666667%;
  *margin-left: 16.5666666667%;
}

.offset-1 {
  margin-left: 8.3333333333%;
  *margin-left: 8.2333333333%;
}

@media (min-width: 576px) {
  .offset-xs-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .offset-xs-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .offset-xs-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-xs-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .offset-xs-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .offset-xs-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-xs-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .offset-xs-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .offset-xs-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-xs-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .offset-xs-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .offset-xs-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media (min-width: 768px) {
  .offset-sm-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .offset-sm-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .offset-sm-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .offset-sm-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .offset-sm-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media (min-width: 992px) {
  .offset-md-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .offset-md-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .offset-md-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .offset-md-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .offset-md-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media (min-width: 1200px) {
  .offset-lg-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .offset-lg-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .offset-lg-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .offset-lg-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .offset-lg-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
@media (min-width: 1600px) {
  .offset-xlg-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .offset-xlg-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .offset-xlg-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-xlg-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .offset-xlg-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .offset-xlg-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-xlg-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .offset-xlg-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .offset-xlg-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-xlg-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .offset-xlg-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .offset-xlg-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}
.wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.no-wrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.no-wrap [class*=col-] {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}

.wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.direction-row-reverse {
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.direction-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.direction-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.align-start {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.align-end {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.align-end [class*=col-] {
  vertical-align: bottom;
}

.align-center {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.align-center [class*=col-] {
  vertical-align: middle;
}

.align-baseline {
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.align-content-start {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

.align-content-end {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}
.align-content-end [class*=col-] {
  vertical-align: bottom;
}

.align-content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}

.align-content-space-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
  align-content: space-between;
}

.align-content-space-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
  align-content: space-around;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  -webkit-align-self: stretch;
  align-self: stretch;
}

.align-self-start {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.align-self-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  vertical-align: bottom;
}

.align-self-center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  vertical-align: middle;
}

.align-self-baseline {
  -ms-flex-item-align: baseline;
  -webkit-align-self: baseline;
  align-self: baseline;
  vertical-align: baseline;
}

.justify-start {
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.justify-start.grid {
  text-align: left;
}

.justify-end {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.justify-end.grid {
  text-align: right;
  -moz-text-align-last: right;
  text-align-last: right;
}
.justify-end.grid [class*=col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-center.grid {
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
}
.justify-center.grid [class*=col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.justify-space-between.grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}
.justify-space-between.grid [class*=col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.justify-space-around.grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}
.justify-space-around.grid [class*=col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.grid-bleed [class*=col-] {
  padding: 0;
}

.col-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.col-grid.direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.col-bleed {
  padding: 0;
}

.col-bleed-x {
  padding: 15px 0;
}

.col-bleed-y {
  padding: 0 15px;
}

.flex-img {
  display: block;
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  height: auto;
  width: 100%;
  *width: auto;
}

.flex-footer {
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
}
.flex-footer > :last-child {
  margin-bottom: 0;
}

@media (min-width: 0px) and (max-width: 575px) {
  .hidden-xxs {
    display: none;
  }
}

@media (min-width: 576px) {
  .hidden-xs-up {
    display: none;
  }
}

@media (max-width: 767px) {
  .hidden-xs-down {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
}

@media (min-width: 768px) {
  .hidden-sm-up {
    display: none;
  }
}

@media (max-width: 991px) {
  .hidden-sm-down {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none;
  }
}

@media (min-width: 992px) {
  .hidden-md-up {
    display: none;
  }
}

@media (max-width: 1199px) {
  .hidden-md-down {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hidden-lg-up {
    display: none;
  }
}

@media (max-width: 1599px) {
  .hidden-lg-down {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .hidden-lg {
    display: none;
  }
}

@media (min-width: 1600px) {
  .hidden-xlg {
    display: none;
  }
}

.banner-form__description .text-field__box {
  padding-left: 0;
  padding-right: 0;
}

.glossary-form__keywords .select-box__width--small .select-box__control {
  width: 100%;
}
.glossary-form__keywords .select-box__label--small {
  display: flex;
  font-size: 1.6rem;
  margin-bottom: 0.3rem;
}

.coming-soon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 15%;
}
.coming-soon__content-icon {
  background-color: #f8f8f8;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}
.coming-soon__content-text {
  color: #888c9d;
  font-size: larger;
  font-weight: bolder;
  padding-top: 10px;
}